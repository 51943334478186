<template>
  <div class="mt-4">
    <span class="mb-4" v-text="$t('request_reset_link_copy')" />
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="authResetPassword"
      @submit="submit"
    >
      <FormulateInput
        v-model.trim="values.email"
        type="email"
        name="email"
        :label="$t('email_label')"
        autocomplete="off"
        validation="bail|required|email"
      />
      <FormulateInput
        type="password"
        name="password"
        autocomplete="new-password"
        :label="$t('new_password')"
        :validation="[
          ['bail'],
          ['required'],
          ['min', 6, 'length'],
          [
            'matches',
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
          ]
        ]"
        :validation-messages="{ matches: $t('password_strength_validation') }"
      />
      <FormulateInput
        type="password"
        name="password_confirm"
        autocomplete="new-password"
        :label="$t('confirm_new_password')"
        :show-password-button="false"
        validation="bail|^required|confirm:password"
      />
      <FormulateErrors />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :input-class="['w-full md:w-auto']"
        :outer-class="['mt-7.5']"
      >
        <i
          :class="[
            'far mr-2',
            isLoading ? 'fa-spinner-third animate-spin' : 'fa-user-lock'
          ]"
        />
        {{ $t('set_password') }}
      </FormulateInput>
    </FormulateForm>
  </div>
</template>

<script>
import { resetPassword } from '@/services/apiService';

export default {
  name: 'AuthResetPassword',
  data() {
    return {
      values: {
        key: this.$route.params.token
      }
    };
  },
  methods: {
    submit(data) {
      delete data.password_confirm;

      return resetPassword(data)
        .then(() => {
          this.$router.push({ name: 'ResetPasswordSuccess' });
        })
        .catch(error => {
          this.$formulate.handle(error, 'authResetPassword');
        });
    }
  }
};
</script>
