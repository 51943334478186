var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('span',{staticClass:"mb-4",domProps:{"textContent":_vm._s(_vm.$t('request_reset_link_copy'))}}),_c('FormulateForm',{attrs:{"name":"authResetPassword"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"email","name":"email","label":_vm.$t('email_label'),"autocomplete":"off","validation":"bail|required|email"},model:{value:(_vm.values.email),callback:function ($$v) {_vm.$set(_vm.values, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"values.email"}}),_c('FormulateInput',{attrs:{"type":"password","name":"password","autocomplete":"new-password","label":_vm.$t('new_password'),"validation":[
        ['bail'],
        ['required'],
        ['min', 6, 'length'],
        [
          'matches',
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
        ]
      ],"validation-messages":{ matches: _vm.$t('password_strength_validation') }}}),_c('FormulateInput',{attrs:{"type":"password","name":"password_confirm","autocomplete":"new-password","label":_vm.$t('confirm_new_password'),"show-password-button":false,"validation":"bail|^required|confirm:password"}}),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['w-full md:w-auto'],"outer-class":['mt-7.5']}},[_c('i',{class:[
          'far mr-2',
          isLoading ? 'fa-spinner-third animate-spin' : 'fa-user-lock'
        ]}),_vm._v(" "+_vm._s(_vm.$t('set_password'))+" ")])]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }